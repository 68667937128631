<template>
  <base-modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal-info-entity">
      <div class="modal__topbar">
        <button class="button modal__close">
          <i class="icon icon-close"></i>
        </button>
      </div>

      <div></div>

      <div class="modal__content">
        <div class="modal__header">
          <h2 class="modal__title">
            Cadastrar coleta
          </h2>
        </div>
        <div class="modal__form--group">
          <div class="dropdown_form">
            <label for="collect">Coletor</label>
            <select name="collect" id="collect" class="select_form">
              <option value="name">Selecione..</option>
              <option value="name">Selecione..</option>
              <option value="name">Selecione..</option>
              <option value="name">Selecione..</option>
            </select>
          </div>

          <div class="dropdown_form">
            <label for="generator">Gerador</label>
            <select name="generator" id="generator" class="select_form">
              <option value="name">Selecione..</option>
              <option value="name">Selecione..</option>
              <option value="name">Selecione..</option>
              <option value="name">Selecione..</option>
            </select>
          </div>
          <div class="dropdown_form">
            <label for="material">Material</label>
            <select name="material" id="material" class="select_form">
              <option value="name">Selecione..</option>
              <option value="name">Selecione..</option>
              <option value="name">Selecione..</option>
              <option value="name">Selecione..</option>
            </select>
          </div>
          <div class="input-double" style="display: flex;">
            <div class="dropdown_form">
              <label for="qtd">Quantidade</label>
              <input type="text" id="qtd" name="qtd" class="input_text" />
            </div>
            <div class="dropdown_form">
              <label for="total">Total pago</label>
              <input type="text" id="total" name="total" class="input_text" />
            </div>
          </div>
          <div class="footer_modal">
            <button type="button" class="button_outline">
              Cadastrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script>
export default {
  props: {
    modal: Boolean
  },

  components: {
    baseModal: () => import('@/components/modal')
  }
}
</script>

<style>
.input_double {
  display: flex;
  justify-content: space-between;
}
</style>
